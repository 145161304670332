<template>
  <div>
    <b-modal
      :id="modalId"
      hide-footer
      hide-header
      size="xl"
      centered
      ignore-enforce-focus-selector="#add-new-consult-sidebar"
      v-model="conditionOrder"
      @hide="onHide"
    >
      <div class="mb-13 mt-2 text-center">
        <h2 class="mb-1">{{ modalTitle }}</h2>
        <div class="text-muted">
          If you need more info, please check see the
          <router-link to="tutorial" class="fw-bold link-primary">
            tutorials</router-link
          >.
        </div>
      </div>
      <validation-observer ref="refFormObserver">
        <b-form @submit.prevent="onSubmit">
          <div class="mx-2">
            <div class="d-flex justify-content-between" v-if="itemStockOptions">
              <div class="pb-2 stock">
                <app-collapse>
                  <app-collapse-item
                    v-for="(stock, index) in itemStockOptions"
                    :key="stock.categoryId"
                    :title="stock.name"
                    :id="`${stock.categoryId}-${stock.name}`"
                    @visible="(event) => (stock.visible = event)"
                  >
                    <div class="d-flex justify-content-center mt-2">
                      <v-select
                        v-model="searchText[index]"
                        :options="stock.items"
                        :reduce="(e) => e.name"
                        label="name"
                        @input="(e) => filterItems(stock, index)"
                        style="width: 100%"
                        placeholder="Search Items"
                      />
                      <b-popover
                        v-if="!stock.visible"
                        :target="`${stock.categoryId}-${stock.name}`"
                        placement="top"
                        :title="`Items selected of ${stock.name}`"
                        triggers="hover focus"
                      >
                        <h6
                          v-for="selected in getSelected(stock)"
                          :key="selected"
                        >
                          {{ selected }}
                        </h6>
                      </b-popover>
                    </div>
                    <div class="d-flex flex-wrap">
                      <b-card
                        v-for="(item, index) in stock.items"
                        :key="item.itemId"
                        style="width: 17rem"
                      >
                        <b-img
                          v-if="item.image"
                          :src="item.image"
                          fluid
                          width="200px"
                        >
                        </b-img>
                        <b-img
                          v-else
                          src="https://sudbury.legendboats.com/resource/defaultProductImage"
                          width="200px"
                        >
                        </b-img>
                        <div class="text-center">
                          <div class="d-flex flex-column align-items-start">
                            <h6>
                              Name:
                              <span class="font-weight-bolder"
                                >{{ item.name }}
                              </span>
                            </h6>
                            <h6>
                              Presentation:
                              <span class="font-weight-bolder"
                                >{{ item.presentation }}
                              </span>
                            </h6>
                          </div>
                          <v-select
                            v-model="item.quantity"
                            :reduce="(item) => item.value"
                            :options="optionsQuantity"
                            :disabled="seeOrder"
                            :selectable="
                              (option) =>
                                isEdit ? option.value > item.quantity : option
                            "
                            :clearable="!isEdit"
                          />
                        </div>
                      </b-card>
                    </div>
                  </app-collapse-item>
                </app-collapse>
              </div>
              <div class="styled-card">
                <h4>
                  Selected Items - {{ getTotalSelected(itemStockOptions) }}
                </h4>
                <h6
                  v-for="(selected, i) in itemsSelected"
                  :key="`${selected}-${i}`"
                >
                  {{ selected }}
                </h6>
              </div>
            </div>
            <div class="d-flex justify-content-between" v-if="!seeOrder">
              <!-- File -->
              <validation-provider
                #default="{ errors }"
                name="File"
                rules="required"
                class="w-100 mx-2"
              >
                <b-form-group
                  label="File"
                  label-for="file"
                  v-if="!stateData.order_atachment"
                >
                  <b-form-file
                    v-model="stateData.order_atachment"
                    @change="onFileChange($event)"
                  ></b-form-file>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
                <b-form-group label="File" label-for="file" v-else>
                  <b-input-group>
                    <b-form-input
                      :value="
                        stateData.order_atachment.name
                          ? stateData.order_atachment.name
                          : 'File'
                      "
                      disabled
                    />
                    <b-input-group-append
                      v-if="!stateData.order_atachment.name"
                    >
                      <b-button variant="outline-primary" v-b-modal.image
                        ><feather-icon icon="EyeIcon"
                      /></b-button>
                    </b-input-group-append>
                    <b-input-group-append>
                      <b-button
                        variant="outline-danger"
                        @click="stateData.order_atachment = null"
                        ><feather-icon icon="TrashIcon"
                      /></b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </validation-provider>
              <!-- Note -->
              <validation-provider
                #default="{ errors }"
                name="Note"
                rules="required"
                class="w-100"
              >
                <b-form-group label="Note" label-for="note">
                  <b-form-textarea
                    id="note"
                    class="textarea pt-3"
                    v-model="stateData.notes"
                  ></b-form-textarea>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </div>

            <div class="d-flex justify-content-center mt-2">
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                class="mr-1"
                variant="outline-danger"
                @click="onHide"
              >
                {{ $t("Cancel") }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                type="submit"
                :disabled="loading"
                v-if="!seeOrder"
              >
                <span v-if="!loading">Save</span>
                <span v-else>
                  <SpinnerLoading />
                </span>
              </b-button>
            </div>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormFile,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BButton,
  BCard,
  BImg,
  BFormTextarea,
  BPopover,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";

import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

import { ref, watch } from "@vue/composition-api";

import ToastNotification from "@/components/ToastNotification";
import SpinnerLoading from "@/components/SpinnerLoading";
import NewItemSelect from "@/components/NewItemSelect";
import showFile from "@/components/showFile";
import AppointmentMembership from "@/components/scanQr.vue";

import axiosStock from "@/services/admin/inventory/stock";
import axiosOrder from "@/services/admin/inventory/order";
import axiosCreateImage from "@/services/external-settings/images";
import { notificationCheck } from "@/mixins/NotificationSetup";

export default {
  components: {
    showFile,
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BPopover,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BButton,
    BCard,
    BImg,
    BFormTextarea,
    ToastNotification,
    NewItemSelect,
    AppointmentMembership,
    SpinnerLoading,
    vSelect,
    AppCollapse,
    AppCollapseItem,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    facilitySelected: {
      type: Object,
      required: true,
    },
    orderItem: {
      type: Object,
      default: () => ({}),
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    seeOrder: {
      type: Boolean,
      default: false,
    },
    modalTitle: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const modalId = `order-form-modal-${Math.random()
      .toString(36)
      .substr(2, 9)}`;
    const conditionOrder = ref(false);
    const stateData = ref({
      order_atachment: props.orderItem.order_atachment || null,
      notes: props.orderItem.notes || "",
      order_statuses_id: props.orderItem.orderItemId || null,
      facility_id: props.facilitySelected.id || null,
    });
    const loading = ref(false);
    const optionsQuantity = [
      { label: "1", value: "1" },
      { label: "2", value: "2" },
      { label: "3", value: "3" },
      { label: "4", value: "4" },
      { label: "5", value: "5" },
      { label: "6", value: "6" },
      { label: "7", value: "7" },
      { label: "8", value: "8" },
      { label: "9", value: "9" },
      { label: "10", value: "10" },
      { label: "11", value: "11" },
      { label: "12", value: "12" },
      { label: "13", value: "13" },
      { label: "14", value: "14" },
      { label: "15", value: "15" },
      { label: "16", value: "16" },
      { label: "17", value: "17" },
      { label: "18", value: "18" },
      { label: "19", value: "19" },
      { label: "20", value: "20" },
    ];

    const searchText = ref([]);
    const itemsSelected = ref([]);
    const refFormObserver = ref(null);
    const itemStockOptions = ref([]);
    const changePhoto = ref(false);
    const fileResource = ref(null);
    let isFiltered = false;
    let tempObj, defaultItems;
    watch(conditionOrder, (newVal, oldVal) => {
      stateData.value.facility_id = props.facilitySelected.id;
      stateData.value.order_atachment = props.orderItem.order_atachment || null;
      stateData.value.notes = props.orderItem.notes;
      showItemStock(stateData.value.facility_id);
    });
    const showItemStock = (value) => {
      axiosStock.stockListByFacility(value).then(({ registro: { stock } }) => {
        if (props.isEdit) {
          const tempArr = stock.map((res) => {
            let newItem = res;
            props.orderItem.order_items.forEach((ea) => {
              if (res.items) {
                const index = res.items.findIndex(
                  (itm) => itm.itemId == ea.items_id
                );
                if (index > -1) newItem.items[index].quantity = ea.quantity;
              }
            });
            newItem.items = newItem.items.filter((e) => e.quantity);
            if (newItem && newItem.items.length)
              return {
                ...newItem,
                visible: false,
              };
          });
          itemStockOptions.value = tempArr.filter(Boolean);
        } else {
          itemStockOptions.value = stock.map((res) => {
            return {
              ...res,
              quantity: 0,
              visible: false,
            };
          });
        }
        tempObj = structuredClone(itemStockOptions.value);
      });
    };

    const getSelected = (stock) => {
      return stock.items
        .filter((item) => item.quantity)
        .map((item) => `${item.name} - ${item.quantity}`);
    };

    const filterItems = (stock, index) => {
      if (!searchText.value[index]) {
        isFiltered = false;
        defaultItems = [];
        return (itemStockOptions.value[index] = tempObj[index]);
      }
      isFiltered = true;
      defaultItems = [...itemStockOptions.value];
      const newStock = {
        ...stock,
        items: stock.items.filter((i) =>
          i.name.toLowerCase().includes(searchText.value[index].toLowerCase())
        ),
      };
      itemStockOptions.value[index] = newStock;
    };

    const getTotalSelected = (stocks) => {
      let total = 0;
      let globalIndex = 0;

      const processItems = (orders) => {
        orders.forEach((order) => {
          order.items.forEach((stock) => {
            if (!stock.quantity) {
              itemsSelected.value[globalIndex] = "";
            } else {
              total += 1;
              itemsSelected.value[
                globalIndex
              ] = `${stock.name} - ${stock.quantity}`;
            }
            globalIndex++;
          });
        });
      };

      if (!isFiltered) {
        processItems(stocks);
      } else {
        processItems(defaultItems);
      }

      return total;
    };

    const uploadPhoto = async (file) => {
      const formData = new FormData();
      formData.append("type", "CARD_DOCUMENT");
      formData.append("file", file);
      const { dir_img } = await axiosCreateImage.createImage(formData);
      return dir_img;
    };

    const onFileChange = (event) => {
      if (event.target.files[0]) {
        fileResource.value = event.target.files[0];
        changePhoto.value = true;
      } else {
        fileResource.value = null;
        changePhoto.value = false;
      }
    };

    const onSubmit = async () => {
      const check = await refFormObserver.value.validate();
      if (check) {
        loading.value = true;
        let fileUrl = null;
        if (changePhoto.value) {
          fileUrl = await uploadPhoto(fileResource.value);
          changePhoto.value = false;
        }

        const itemsInventory = [];
        itemStockOptions.value.forEach((res) => {
          res.items.forEach((res) => {
            if (res.quantity > 0) {
              itemsInventory.push({
                id: res.itemId,
                quantity: res.quantity,
              });
            }
          });
        });

        const data = {
          order_items: itemsInventory,
          ...stateData.value,
          order_statuses_id: stateData.value.order_statuses_id || 1,
          order_atachment: fileUrl,
        };
        axiosOrder[props.isEdit ? "orderUpdate" : "orderCreate"](
          data,
          props.orderItem.id
        )
          .then((data) => {
            const { type, message } = notificationCheck(data);
            emit("createOrder", {
              type,
              message,
            });
            conditionOrder.value = false;
            loading.value = false;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };

    const onHide = () => {
      conditionOrder.value = false;
    };

    return {
      modalId,
      conditionOrder,
      stateData,
      loading,
      optionsQuantity,
      searchText,
      itemsSelected,
      itemStockOptions,
      refFormObserver,
      getSelected,
      filterItems,
      getTotalSelected,
      onFileChange,
      onSubmit,
      onHide,
    };
  },
};
</script>

<style scoped>
.styled-card {
  border: 1px solid #5483b7;
  border-radius: 15px;
  width: 18%;
  margin-left: 1vw;
  padding: 1vw;
}
.stock {
  width: 82%;
}
</style>
