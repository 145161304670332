import { userAxios } from '@/services'

const StockList = async (perPage, facility) => {
    try {
        return await userAxios.get(`stock/index/${perPage}/${facility}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const stockListByFacility = async (facility) => {
    try {
        return await userAxios.get(`stock/facility/${facility}/stock-list`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const StockCreate = async (data) => {
    try {
        return await userAxios.post('stock', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const StockUpdate = async (id, data) => {
    try {
        return await userAxios.put(`stock/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const StockDelete = async (id) => {
    try {
        return await userAxios.delete(`stock/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const StockPagination = async (perPage, facility, page) => {
    try {
        return await userAxios.get(`stock/index/${perPage}/${facility}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const StockFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`stock/filter/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const StockFilterPagination = async (perPage, page, data ) => {
    try {
        return await userAxios.post(`stock/filter/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const stockByFacility = async (facility) => {
    try {
        return await userAxios.get(`stock/show_by_facility/${facility}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

export default {
    StockList,
    stockListByFacility,
    StockCreate,
    StockUpdate,
    StockDelete,
    StockPagination,
    StockFilter,
    StockFilterPagination,
    stockByFacility
}