<template>
  <b-modal v-model="conditionCancel" @hide="onHide" hide-footer hide-header centered size="md">
    <validation-observer #default="{ handleSubmit }">
      <b-form class="auth-login-form mt-2" @submit.prevent="handleSubmit(onSubmitCancel)">
        <!-- internal payment note-->
        <b-tabs content-class="pt-1" fill>
          <b-tab active title="Lector">
            <!-- imagen de espera -->
            <b-img src="@/assets/images/banner/upgrade.png" rounded alt="lector"></b-img>

            <b-form-input ref="miInput" v-model="dataQr.scanQr" @input="empezarTemporizador" type="text"
              class="hidden-text">
            </b-form-input>
          </b-tab>
          <b-tab title="Camara">
            <qrcode-stream @decode="onDecode"> </qrcode-stream>
          </b-tab>
        </b-tabs>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BFormInput,
  BButton,
  BTabs,
  BTab,
  BImg,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { QrcodeStream } from "vue-qrcode-reader";

import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";

import SpinnerLoading from "@/components/SpinnerLoading";
import { notificationCheck } from "@/mixins/NotificationSetup";

import axiosUS from "@/services/utilities/scan";
import membership from "@/services/admin/membership";

export default {
  components: {
    BModal,
    BForm,
    BFormInput,
    BButton,
    BTabs,
    BTab,
    BImg,

    flatPickr,
    vSelect,
    QrcodeStream,

    ValidationProvider,
    ValidationObserver,

    SpinnerLoading,
  },
  props: {
    typeQr: {
      type: String,
      default: "membership",
      required: false,
    },
    userId: {
      type: [Number, String],
      required: false,
    },
  },
  mounted() {
    this.focusInterval = setInterval(() => {
      this.$refs.miInput.focus();
    }, 1000);
  },
  data() {
    return {
      conditionCancel: true,
      conditionSplit: true,
      loading: false,
      dataQr: {
        scanQr: null,
        user: this.userId,
      },
      focusInterval: null,
      temporizador: null,
      tiempoEspera: 500,
    };
  },
  methods: {
    empezarTemporizador() {
      clearTimeout(this.temporizador);
      this.temporizador = setTimeout(this.finalizarEscritura, this.tiempoEspera);
    },
    finalizarEscritura() {
      if (!this.dataQr.scanQr) return
      const waos = {
        bfCheckout: this.onSubmitQrfinishingAppointment,
        membership: this.onSubmitQrMembership,
        cupon: this.onSubmitQrCupon
      }
      if (waos.hasOwnProperty(this.typeQr)) return waos[this.typeQr]();
      return this.onSubmitQrMembership();
    },
    onDecode(result) {
      this.dataQr.scanQr = result;
      this.finalizarEscritura();
    },
    onHide() {
      this.$emit("closeModalPay", false);
    },
    async onSubmitQrMembership() {
      if (!this.dataQr.scanQr) return
      this.loading = true;
      const data = {
        ...this.dataQr,
      };
      axiosUS.validateQr(data)
        .then((res) => {
          this.loading = false;
          const data = {
            ...res,
            qr: this.dataQr.scanQr,
          };
          this.$emit("successModalQr", data);
          this.$emit("closeModalPay", false);
          this.$emit("notificationCheck", res);
        })
        .catch((e) => {
          this.loading = false;
          this.$emit("closeModalPay", false);
        });
    },
    async onSubmitQrfinishingAppointment() {
      if (!this.dataQr.scanQr) return
      this.loading = true;
      const data = {
        scanQr: this.dataQr.scanQr,
        appointment_id: this.userId
      };
      axiosUS
        .scanQrAppointment(data)
        .then((res) => {
          this.loading = false;
          const data = {
            ...res,
            qr: this.dataQr.scanQr,
          };
          this.$emit("successModalQr", data);
          this.$emit("closeModalPay", false);
          this.$emit("notificationCheck", res);
        })
        .catch((e) => {
          this.loading = false;
          this.$emit("closeModalPay", false);
        });
    },
    async onSubmitQrCupon() {
      if (!this.dataQr.scanQr) return
      this.loading = true;
      const data = {
        cupon: this.dataQr.scanQr,
      };
      axiosUS
        .verifyCupon(data)
        .then((res) => {
          this.loading = false;
          const data = {
            ...res,
            qr: this.dataQr.scanQr,
          };
          this.$emit("successModalQr", data);
          this.$emit("closeModalPay", false);
          this.$emit("notificationCheck", res);
        })
        .catch((e) => {
          this.loading = false;
          this.$emit("closeModalPay", false);
        });
    },
  },
  beforeDestroy() {
    clearInterval(this.focusInterval);
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.hidden-text {
  position: absolute;
  left: -9999px;
}
</style>
