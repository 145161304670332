<template>
    <div>
        <div v-if="isImage">
            <a :href="fileUrl" target="_blank" rel="noopener noreferrer" download="false">
                <img :src="fileUrl" alt="Click here to download the resource" :style="styles">
            </a>
        </div>
        <div v-else-if="!isImage">
            <a :href="fileUrl" target="_blank" rel="noopener noreferrer" download="false">
                <embed :src="fileUrl" type="application/pdf" :style="styles">
            </a>
        </div>
        <div v-else>
            <p>This file type is not supported for viewing in the browser.</p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        fileUrl: {
            type: String,
            required: true,
        },
        styles: {
            required: false,
            default: "width: 100%; height: 600px;",
            type: String
        }
    },
    computed: {
        isImage() {
            if(typeof this.fileUrl == "string") return /\.(jpg|jpeg|png|gif|heic)$/i.test(this.fileUrl);
            return "xddd"
        }
    }
};
</script>

<style scoped>
/* Estilos opcionales para personalizar la apariencia del componente */
</style>