import { userAxios } from '@/services'

const orderList = async (perPage) => {
    try {
        return await userAxios.get(`item_orders/facility/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const orderView = async (orderId) => {
    try {
        return await userAxios.get(`item_orders/${orderId}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const orderCreate = async (data) => {
    try {
        return await userAxios.post('item_orders/manuals', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const orderUpdate = async (data, id) => {
    try {
        return await userAxios.post(`item_orders/manuals/update/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const orderDelete = async (id) => {
    try {
        return await userAxios.delete(`item_orders/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const orderPagination = async (perPage, facility, page) => {
    try {
        return await userAxios.get(`item_orders/index/${perPage}/${facility}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const orderFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`item_orders/filter/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const orderFilterPagination = async (perPage, page, data ) => {
    try {
        return await userAxios.post(`item_orders/filter/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

export default {
    orderList,
    orderView,
    orderCreate,
    orderUpdate,
    orderDelete,
    orderPagination,
    orderFilter,
    orderFilterPagination,
}