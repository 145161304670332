import { imageAxios } from '../index'

/**
 * It takes an image, uploads it to cloudinary, and returns the response data.
 * @param image - The image file to upload.
 * @returns The image object
 */
const createImage = async (image) => {
    try {
        return await imageAxios.post(``, image).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

export default {
    createImage,
}