<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>{{ $t("Filters") }}</h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <!-- <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>Facility</label>
          <v-select
            v-model="filters.facility_id"
            class="w-100"
            :options="facilityOptions"
            :reduce="(value) => value.id"
            label="name"
            @keyup.enter="searchFilter"
            @input="searchFilter"
          />
        </b-col> -->
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>{{ $t("message.tableHeader.name") }}</label>
          <b-form-input
            autofocus
            v-model.trim="filters.name"
            @keyup.enter="searchFilter"
          />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>{{ $t("Form.Status") }}</label>
          <v-select
            v-model="filters.status"
            class="w-100"
            :options="statusOptions"
            :reduce="(value) => value.value"
            label="name"
            @keyup.enter="searchFilter"
          />
        </b-col>
        <b-col cols="12" class="mb-md-0 mb-2 mt-2" align="end">
          <b-button variant="warning" @click="resetFilter" class="mr-1">
            {{ $t("Clear") }}
          </b-button>
          <b-button variant="primary" @click="searchFilter" :disabled="loading">
            <span v-if="!loading">{{ $t("Search") }}</span>
            <span v-else>
              <SpinnerLoading />
            </span>
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";

import SpinnerLoading from "@/components/SpinnerLoading";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormInput,
    BButton,
    vSelect,
    SpinnerLoading,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.filters.facility_id = this.$variableGlobal.facility.id
    this.searchFilter()
  },
  data() {
    return {
      stateOptions: [],
      facilityOptions: [],
      filters: {
        name: null,
        status: null,
        facility_id: null,
      },
      statusOptions: [
        { name: "Active", value: "true" },
        { name: "Inactive", value: "false" },
      ],
    };
  },
  methods: {
    searchFilter() {
      this.filters.facility_id = this.$variableGlobal.facility.id
      const filter = this.filters;
      let cont = 0;
      for (const iterator in filter) {
        if (this.filters[iterator] == "" || this.filters[iterator] == null) {
          cont++;
        }
      }
      if (Object.keys(filter).length == cont) this.$emit("filterPage", false);
      else this.$emit("filterPage", true);

      this.$emit("searchFilter", JSON.parse(JSON.stringify(this.filters)));
    },
    resetFilter() {
      const filter = JSON.parse(JSON.stringify(this.filters));
      for (const iterator in filter) {
        this.filters[iterator] = null;
      }
    },
  },
};
</script>
