import { userAxios } from '@/services'

const membershipList = async (perPage) => {
    try {
        return await userAxios.get(`membership/index/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const membershipCreate = async (data1, data2) => {
    try {
        const data = data2 ? data2 : data1
        return await userAxios.post('membership/store', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const membershipShow = async (id) => {
    try {
        return await userAxios.get(`membership/show/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const membershipUpdate = async (id, data) => {
    try {
        return await userAxios.put(`membership/update/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const membershipDelete = async (id) => {
    try {
        return await userAxios.delete(`membership/delete/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const membershipPagination = async (perPage, page) => {
    try {
        return await userAxios.get(`membership/index/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const membershipFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`membership/filter/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const membershipFilterPagination = async (perPage, page, data ) => {
    try {
        return await userAxios.post(`membership/filter/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}
export default {
    membershipList,
    membershipCreate,
    membershipUpdate,
    membershipDelete,
    membershipPagination,
    membershipFilter,
    membershipFilterPagination,
    membershipShow
}