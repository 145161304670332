<template>
  <div>
    <span class="text-muted">No se encontraron resultados.</span>
    <br />
    ¿Crear nuevo item?
    <b-input-group class="input-group-merge">
      <b-form-input id="new-item" :value="search" type="text" />
      <b-input-group-append is-text>
        <feather-icon
          icon="SendIcon"
          size="20"
          class="cursor-pointer text-primary"
            @click="onSaveNewItem"
        />
      </b-input-group-append>
    </b-input-group>
  </div>
</template>

<script>
import { BInputGroup, BInputGroupAppend, BFormInput } from "bootstrap-vue";
export default {
  components: {
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
  },
  props: {
    search: {
      type: String,
      default: "",
    },
  },
  methods: {
    onSaveNewItem() {
      this.$emit("onSaveVisitReason", true);
    },
  },
};
</script>

<style></style>
