<template>
    <div>
        <b-button pill variant="outline-primary" class="mb-1">
            <feather-icon icon="ArrowLeftIcon" @click="GoBack()"/>
        </b-button>
        <embed :src="externalUrl" width="100%" height="800vh" />
    </div>
</template>

<script>
import {BButton} from 'bootstrap-vue'
export default {
    components: {
        BButton,
    },
    props: {
        externalUrl: {
            type: String,
            required: true,
        },
    },
    methods: {
        GoBack() {
            this.$emit("goBack")
        }
    }
};
</script>