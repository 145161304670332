import { userAxios } from '../index'

const scanQrAppointment = async (data) => {
    try {
        return await userAxios.post(`appointment/scan-qr`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const validateQr = async (data) => {
    try {
        return await userAxios.post(`scan/validateQr`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const scanQrPatient = async (data) => {
    try {
        return await userAxios.post(`scan/validateQr-user`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const verifyCupon = async (data) => {
    try {
        return await userAxios.post(`campaigns-cupons/verify-cupon`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

export default {
    scanQrAppointment,
    scanQrPatient,
    verifyCupon,
    validateQr
}