<template>
  <b-skeleton-table
    :rows="rows"
    :columns="columns"
    :table-props="{ bordered: true, hover: true, striped: true }"
  ></b-skeleton-table>
</template>

<script>
import { BSkeletonTable } from "bootstrap-vue";
export default {
  components: {
    BSkeletonTable,
  },
  props: {
    rows: {
      type: Number,
      default: 5,
    },
    columns: {
      type: Number,
      default: 4,
    },
  },
};
</script>

<style></style>
