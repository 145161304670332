<template>
  <div>
    <OrderFilter
      v-if="$can('filter', 'module_modules.type_of_modules.inventory.order')"
      :loading="loading"
      @searchFilter="searchFilter"
      @filterPage="filterPage"
    />
    <b-card>
      <TitleTable titleTable="Order" iconTable="GitBranchIcon" />
      <div class="m-1">
        <OrderForm
          ref="orderAddForm"
          :facility-selected="facilitySelected"
          :is-edit="false"
          :createOrder="createOrder"
          modalTitle="Create Order"
        />
        <OrderForm
          ref="orderForm"
          :facility-selected="facilitySelected"
          :createOrder="createOrder"
          :orderItem="editItem"
          :is-edit="true"
          modalTitle="Edit Order"
        />
        <OrderForm
          ref="seeOrder"
          :facility-selected="facilitySelected"
          :createOrder="createOrder"
          :orderItem="editItem"
          :is-edit="true"
          :seeOrder="true"
          modalTitle="See Order"
        />
        <b-modal v-model="seeFile" size="xl" hide-footer>
          <EmbedComponent :externalUrl="urlFile" @goBack="closeFileModal" />
        </b-modal>
        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t("Show") }}</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t("Entries") }}</label>
          </b-col>
          <!-- {{ $t("Search") }} -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <GoBack class="mr-2" />
              <b-button
                v-if="
                  $can(
                    'store',
                    'module_modules.type_of_modules.inventory.order'
                  )
                "
                variant="primary"
                @click="openOrder"
                :disabled="loading"
              >
                <span v-if="!loading" class="text-nowrap"
                  >{{ $t("Add") }} Order</span
                >
                <span v-else>
                  <SpinnerLoading />
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <div v-if="items === null">
        <b-alert show fade class="mb-0 p-1" variant="primary">
          <div class="alert-body text-center">
            <span>Por favor seleccione una facility</span>
          </div>
        </b-alert>
      </div>
      <b-table
        v-else
        :items="items"
        :fields="fields"
        hover
        bordered
        responsive
        primary-key="id"
        class="position-relative"
        show-empty
        empty-text="No matching records found"
      >
        <template #cell(show_details)="row">
          <b-form-checkbox
            v-model="row.detailsShowing"
            class="vs-checkbox-con"
            @change="toggleDetailSurvey(row)"
          >
          </b-form-checkbox>
        </template>
        <template #row-details="row">
          <b-card>
            <b-table :items="viewItem" :fields="fieldsViewItem"></b-table>
            <b-button
              size="sm"
              variant="outline-secondary"
              @click="row.toggleDetails"
            >
              Hide Details
            </b-button>
          </b-card>
        </template>
        <template #cell(status)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              data.item.status ? $t("Active") : $t("Inactive")
            }}</span>
          </div>
        </template>
        <template #cell(created_at)="data">
          {{ new Date(data.item.created_at).toLocaleString() }}
        </template>
        <template #cell(actions)="data">
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-primary"
            class="btn-icon"
            v-b-tooltip.hover.top="'See File'"
            @click="seeModalFile(data.item)"
          >
            <feather-icon icon="FileIcon" />
          </b-button>
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-primary"
            class="btn-icon"
            v-b-tooltip.hover.top="'See order'"
            @click="seeModalOrder(data.item)"
          >
            <feather-icon icon="EyeIcon" />
          </b-button>
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-primary"
            class="btn-icon"
            v-b-tooltip.hover.top="'Edit'"
            v-if="
              $can(
                'update',
                'module_modules.type_of_modules.inventory.order'
              ) && data.item.is_updateable
            "
            @click="updateOrder(data.item)"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-danger"
            class="btn-icon"
            v-b-tooltip.hover.top="'Delete'"
            v-if="
              $can(
                'destroy',
                'module_modules.type_of_modules.inventory.order'
              ) && data.item.is_updateable
            "
            @click="deleteOrder(data.item)"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>
        </template>
      </b-table>
      <PaginationTable
        :dataMeta="dataMeta"
        :totalUsers="totalUsers"
        :perPage="perPage"
        :currentPage="currentPage"
        @page-changed="pageChanged"
      />
    </b-card>
    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BSkeletonTable,
  BBadge,
  BAvatar,
  BAlert,
  BButton,
  VBTooltip,
  BFormCheckbox,
} from "bootstrap-vue";
import axiosOrder from "@/services/admin/inventory/order";

import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";

import ToastNotification from "@/components/ToastNotification";
import SpinnerLoading from "@/components/SpinnerLoading";
import PaginationTable from "@/components/PaginationTable";
import TitleTable from "@/components/TitleTable.vue";
import LoadingTable from "@/components/LoadingTable.vue";
import GoBack from "@/components/ButtonBack.vue";

import { notificationCheck } from "@/mixins/NotificationSetup";

import OrderForm from "./OrderForm.vue";
import OrderFilter from "./OrderFilter.vue";
import EmbedComponent from "@/components/EmbedComponent.vue";

export default {
  components: {
    BCard,
    BRow,
    OrderForm,
    BCol,
    BTable,
    BSkeletonTable,
    BBadge,
    BAvatar,
    BButton,
    BAlert,
    BFormCheckbox,
    EmbedComponent,
    OrderFilter,
    ToastNotification,
    SpinnerLoading,
    PaginationTable,
    TitleTable,
    LoadingTable,
    GoBack,
    vSelect,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      fields: [
        "show_details",
        "id",
        { key: "orderId", label: "Order", sortable: true },
        { key: "users.name", label: "Name", sortable: true },
        { key: "order_statuses.name", label: "Order Status", sortable: true },
        { key: "status", label: "Status" },
        { key: "created_at", label: "Date", sortable: true },
        { key: "actions", label: "Actions" },
      ],
      fieldsViewItem: [
        { key: "items.name", label: "Name", sortable: true },
        { key: "items.description", label: "Description" },
        {
          key: "items.presentations.name",
          label: "Presentation",
          sortable: true,
        },
        { key: "quantity", sortable: true },
      ],
      items: null,
      listItem: null,
      loading: false,
      urlFile: "",
      seeFile: false,
      isAddNewOrderSidebarActive: false,
      isEditOrderSidebarActive: false,
      dataMeta: { from: 1, to: 1, of: 1 },
      totalUsers: 0,
      perPage: 10,
      currentPage: {
        page: 1,
      },
      perPageOptions: [10, 25, 50, 100],
      filtro: false,
      OrderFilter: null,
      facilitySelected: {},
      viewItem: null,
      editItem: {},
    };
  },
  mounted() {
    this.facilitySelected = this.$variableGlobal.facility;
    this.getOrder();
  },
  watch: {
    perPage(newVal) {
      if (!this.filtro) {
        this.getOrder();
      } else {
        this.searchFilter(this.OrderFilter);
      }
    },
    "$variableGlobal.facility": function (value) {
      this.facilitySelected = value;
    },
  },
  methods: {
    checkFileType() {
      const embeddableFormats = {
        img: ["jpg", "jpeg", "png", "gif", "svg"],
        video: ["mp4", "webm", "ogg"],
        audio: ["mp3", "wav", "ogg"],
        embed: ["pdf"],
      };

      const parts = this.urlFile.split(".");
      const extension = parts[parts.length - 1].toLowerCase();
      for (const [component, formats] of Object.entries(embeddableFormats)) {
        if (formats.includes(extension)) {
          this.embedComponent = component;
          return true;
        }
      }

      return false;
    },
    seeModalFile(item) {
      this.urlFile = item.order_atachment;
      if (!this.checkFileType()) {
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = this.urlFile;
        const parts = this.urlFile.split("/");
        const name = parts[parts.length - 1].toLowerCase();
        a.download = name;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        return;
      }
      this.seeFile = true;
    },
    closeFileModal() {
      this.urlFile = "";
      this.seeFile = false;
    },
    openOrder() {
      this.$refs.orderAddForm.conditionOrder = true;
    },
    getOrder() {
      axiosOrder
        .orderList(this.perPage)
        .then(({ data, current_page, total, per_page, ...res }) => {
          this.items = data;
          this.totalUsers = total;
          this.dataMeta.of = total;
          this.dataMeta.from = res.from;
          this.dataMeta.to = res.to;
          this.currentPage.page = current_page;
        });
    },
    createOrder({ type, message }) {
      this.$refs.toast[type](message);
      this.$refs.orderAddForm.conditionOrder = false;
      this.$refs.orderForm.conditionOrder = false;
      this.$refs.seeOrder.conditionOrder = false;
      this.pageChanged(this.currentPage.page);
    },
    editOrder({ type, message }) {
      this.$refs.toast[type](message);
      if (type != "danger") this.$refs.orderForm.conditionOrder = false;
      this.pageChanged(this.currentPage.page);
    },
    updateOrder(order) {
      this.editItem = order;
      this.listItem;
      this.$refs.orderForm.conditionOrder = true;
    },
    seeModalOrder(order) {
      this.editItem = order;
      this.$refs.seeOrder.conditionOrder = true;
    },
    deleteOrder(item) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axiosOrder.orderDelete(item.id).then((response) => {
            this.$refs.toast.success(
              "Order type has been deleted successfully"
            );
            this.pageChanged(this.currentPage.page);
          });
        }
      });
    },
    pageChanged(page) {
      if (!this.filtro) {
        axiosOrder
          .orderPagination(this.perPage, page)
          .then(({ data, total, current_page, ...res }) => {
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          });
      } else {
        axiosOrder
          .orderFilterPagination(this.perPage, page, this.OrderFilter)
          .then(({ data, total, current_page, ...res }) => {
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          });
      }
    },
    searchFilter(value) {
      if (this.filtro) {
        this.loading = true;
        this.OrderFilter = value;
        axiosOrder
          .orderFilter(this.perPage, value)
          .then(({ data, total, current_page, ...res }) => {
            this.loading = false;
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        this.getOrder();
      }
    },
    filterPage(value) {
      this.filtro = value;
    },
    toggleDetailSurvey(value) {
      value.toggleDetails();
      this.items.forEach((item) => {
        if (item.id !== value.item.id) {
          item._showDetails = false;
        }
      });
      this.viewItem = null;
      if (value.detailsShowing) {
        axiosOrder
          .orderView(value.item.id)
          .then(({ registro }) => {
            this.viewItem = registro.order_items;
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
  },
};
</script>

<style></style>
